import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import * as React from "react"
import { useCreditCodeStore } from "../../model/CreditCodeStore"
import AlertModal from "./AlertModal"
import CreditReceipt from "./CreditReceipt"

export default function AddCreditFlow({ isOpen, handleClose, creditId, remainingCreditList }) {
    const removeCreditCode = useCreditCodeStore( (state) => state.removeCreditCode)

    const handleConfirm = () => {
        removeCreditCode(creditId)
        handleClose()
    }

    const message = (
        <Box>
            <CreditReceipt creditCode={creditId} creditMinutes={remainingCreditList[creditId]} />
            <Typography sx={{ pb: 3, px: 3 }}>
                Are you sure you want to forget this credit code on this device?
            </Typography>
        </Box>
    )

    return (
        <AlertModal
            title={"Forget Credit Code"}
            message={message}
            isOpen={isOpen}
            handleClose={handleClose}
            positiveButtonLabel={"Forget"}
            handleConfirm={handleConfirm}
        />
    )
}
