/**
 * DNF, the Ding Notes Format
 */
import { Annotation } from "./Annotation.js"
import { DTF } from "./dtf.mjs"

class DNF {
    dnf_version = 1
    media_filename
    transcript
    annotations = []

    // TODO: set media_filename (constructor)
    constructor(mediaFilename) {
        this.media_filename = mediaFilename
    }

    static fromJSON(object) {
        const dnf = new DNF()
        dnf.dnf_version = 1
        dnf.media_filename = object.media_filename
        if (object.transcript)
            dnf.transcript = DTF.fromJSON(object.transcript)
        if (object.annotations)
            dnf.annotations = object.annotations.map( a => Annotation.fromJSON(a) )
        return dnf
    }

    toJSON() {
        const out = {
            dnf_version: this.dnf_version,
            media_filename: this.media_filename
        }
        if (this.transcript)
            out.transcript = this.transcript.toJSON()
        if (this.annotations)
            out.annotations = this.annotations.map( (a) => a.toJSON() )
        return out
    }
}

export {
    DNF
}