import { useStore } from "zustand"
import { createStore } from "zustand/vanilla"
import * as IdbKeyval from "idb-keyval"
const IDB_KEY_CREDIT_CODE = "billingReceiptCodes"

const creditCodeStore = createStore((set, get) => ({
    codes: [],
    remainingCredits: {},
    remainingCreditMinutes: 0,
    updateRemainingCredits: async () => {
        const codes = (await IdbKeyval.get(IDB_KEY_CREDIT_CODE)) || []
        set({ codes: codes.filter((c) => c) }) // Filter for all truthy strings

        let remainingCredits = {}
        try {
            const res = await fetch(`/checkCredits/${get().codes.join(",")}`)
            remainingCredits = await res.json()
        } catch (e) {
            if (e.name === "SyntaxError") return {}
        }
        set({ remainingCredits: remainingCredits })

        let remainingMinutes = 0
        for (const m of Object.values(remainingCredits)) {
            remainingMinutes += m
        }
        set({ remainingCreditMinutes: remainingMinutes })
    },
    addCreditCode: async (newReceiptCode) => {
        // TODO: make it unique
        await IdbKeyval.update(IDB_KEY_CREDIT_CODE, (codes) => {
            const codeSet = new Set(codes ? codes : [])
            codeSet.add(newReceiptCode)
            return Array.from(codeSet)
        })
        get().updateRemainingCredits()
    },
    removeCreditCode: async (creditCode) => {
        await IdbKeyval.update(IDB_KEY_CREDIT_CODE, (codes) => {
            return codes ? codes.filter((value) => value !== creditCode) : []
        })
        get().updateRemainingCredits()
    },
}))

const useCreditCodeStore = (selector) => useStore(creditCodeStore, selector)
export { creditCodeStore, useCreditCodeStore }
