import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { CaretDown, X } from "@phosphor-icons/react"
import * as React from "react"
import { useJobStore } from "../../model/JobStore"

export default function TranscriptionStatus({ transcript, job, buttonSize = "small" }) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
    const open = Boolean(menuAnchorEl)
    const handleUploadMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setMenuAnchorEl(null)
    }
    const cancelJobUpload = useJobStore( (state) => state.cancelUpload)
    const cancelUpload = () => {
        if (job?.jobId) cancelJobUpload(job.jobId)
        handleClose()
    }

    function hasTranscript() {
        return (
            <Typography variant="button" sx={{ color: grey[400], width: "100%" }}>
                {transcript && (
                    <Button disabled size={buttonSize}>
                        Transcript
                    </Button>
                )}
            </Typography>
        )
    }

    function jobUploading() {
        return (
            <div>
                <Button
                    sx={{ width: "100%" }}
                    startIcon={
                        <CircularProgress
                            size={12}
                            thickness={6}
                            variant="determinate"
                            value={job ? job.uploadProgressPercent : 0}
                        />
                    }
                    endIcon={<CaretDown size={12} weight="bold" />}
                    onClick={handleUploadMenuClick}
                    variant="outlined"
                    size={buttonSize}
                >
                    Uploading
                </Button>
                <Menu onClose={handleClose} id={"upload-menu"} anchorEl={menuAnchorEl} open={open}>
                    <MenuList dense sx={{ p: 0 }}>
                        <MenuItem onClick={cancelUpload}>
                            <ListItemIcon>
                                <X size={16} weight="bold" />
                            </ListItemIcon>
                            <ListItemText>Cancel Upload</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </div>
        )
    }

    function jobTranscribing() {
        return (
            <Button
                sx={{ width: "100%" }}
                variant="outlined"
                size={buttonSize}
                startIcon={<CircularProgress size={12} thickness={6} />}
            >
                Transcribing
            </Button>
        )
    }

    function jobFailed() {
        return (
            <Button sx={{ width: "100%" }} variant="outlined" size={buttonSize}>
                Failed
            </Button>
        )
    }

    if (transcript || (job && job.status === "collected")) return hasTranscript()

    if (job && job.status === "created") return jobUploading()

    if (job && job.status === "failed") return jobFailed()

    if (
        job &&
        ["uploaded", "conversion-started", "converted", "working", "complete"].includes(job.status)
    )
        return jobTranscribing()

    if (job && job.status === "cancelled") return null

    // Job has been created and hasn't started uploading yet
    if (job) return jobUploading()

    return null
}
