import * as React from "react"
import Typography from "@mui/material/Typography"
import HomepageOutline from "./HomepageOutline"
import { styled } from "@mui/material/styles"
import { CaretRight } from "@phosphor-icons/react"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary, { accordionSummaryClasses } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import content from "./HelpContent"

export default function HelpPage({ theme }) {
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&::before": {
            display: "none",
        },
    }))

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<CaretRight sx={{ fontSize: "0.9rem" }} />} {...props} />
    ))(({ theme }) => ({
        backgroundColor: "rgba(0, 0, 0, .03)",
        flexDirection: "row-reverse",
        [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
            transform: "rotate(90deg)",
        },
        [`& .${accordionSummaryClasses.content}`]: {
            marginLeft: theme.spacing(1),
        },
        ...theme.applyStyles("dark", {
            backgroundColor: "rgba(255, 255, 255, .05)",
        }),
    }))

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: "1px solid rgba(0, 0, 0, .125)",
    }))

    const [expanded, setExpanded] = React.useState()

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <HomepageOutline theme={theme}>
            {content.map((item, outerIndex) => {
                return (
                    <div key={`h${outerIndex}`}>
                        <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
                            <b>{item.h}</b>
                        </Typography>
                        <div key={`qa${outerIndex}`}>
                            {item.qa_list.map((qa, innerIndex) => (
                                <Accordion
                                    key={`${outerIndex}${innerIndex}`}
                                    expanded={expanded === `panel${outerIndex}${innerIndex}`}
                                    onChange={handleChange(`panel${outerIndex}${innerIndex}`)}
                                >
                                    <AccordionSummary
                                        aria-controls={`panel${outerIndex}${innerIndex}-content`}
                                        id={`panel${outerIndex}${innerIndex}-header`}
                                    >
                                        <Typography>{qa.q}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>{qa.a}</AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                )
            })}
        </HomepageOutline>
    )
}
