import * as React from "react"
import Grid from "@mui/material/Grid2"
import { grey } from "@mui/material/colors"

export default function EmptyMessage({icon, message}) {
    return (
        <Grid
            container
            direction="column"
            color={grey[400]}
            sx={{
                px: 2,
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                userSelect: "none",
            }}
        >
            <Grid>
                {icon}
            </Grid>
            <Grid>
                {message}
            </Grid>
        </Grid>
    )
}
