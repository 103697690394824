import * as React from "react"
import { useRouteError, useNavigate } from "react-router"

export default function ErrorElement({theme}) {

    const navigate = useNavigate()
    const error = useRouteError();
    
    console.log(error)

    // TODO: report the error
    React.useEffect( () => navigate("/app"), [navigate] )

    return(null)
};