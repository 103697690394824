import { Transcript } from "./Transcript"

export class MediaFile {
    id
    path
    filename

    dnf
    fileHandle
    fileSize
    mediaBlobURL
    peaksBlobURL

    // TODO: access via this.dnf instead
    transcript
    annotations = []

    // TODO: review which fields are used
    constructor({ id, path, filename, fileHandle }) {
        this.id = id
        this.path = path
        this.filename = filename
        this.fileHandle = fileHandle
    }

    basename() {
        return this.filename.replace(/.*\//, "")
    }

    shortName() {
        return this.basename().split(".").slice(0, -1).join(".")
    }

    ext() {
        return this.basename().split(".").slice(-1) || ""
    }

    getBlobUrlSrc() {
        // Bit of a hack, but it seems videojs accepts "video/mp4" for whatever chrome can play
        if (this.peaksBlobURL === undefined)
            return {
                type: "video/mp4",
                src: this.mediaBlobURL,
            }
        else
            return {
                type: "video/mp4",
                src: this.mediaBlobURL,
                peaks: this.peaksBlobURL,
            }
    }

    async loadFiles(localStorage) {
        const mediaFile = await this.fileHandle.getFile()
        this.fileSize = mediaFile.size
        this.mediaBlobURL = URL.createObjectURL(mediaFile)

        const peaksFileHandle = await localStorage.getPeaksFileHandle(this.id)
        if (peaksFileHandle) {
            this.peaksBlobURL = URL.createObjectURL(await peaksFileHandle.getFile())
        }

        this.dnf = await localStorage.getDNF(this.id, this.filename)
        if (this.dnf.transcript)
            this.transcript = Transcript.fromDTF(this.dnf.transcript)
        this.annotations = this.dnf.annotations
    }
}
