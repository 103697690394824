import * as React from "react"
import Typography from "@mui/material/Typography"

export default [
    {
        h: "What's in a name?",
        qa_list: [
            {
                q: "Why is it called Ding?",
                a: (
                    <>
                        <Typography>
                            You work hard and probably deserve a delicious cup of tea. Some kettles
                            whistle when they boil. Others have a bell that goes "ding!".
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            Our automated transcription service is so fast that once your file is
                            uploaded, we'll probably have it ready for you before your kettle goes
                            "ding". This way, you can enjoy reading a highly accurate transcript
                            with a lovely hot beverage in hand.
                        </Typography>
                    </>
                ),
            },
        ],
    },
    {
        h: "Getting Started",
        qa_list: [
            {
                q: "Which web browsers are supported?",
                a: (
                    <>
                        <Typography>
                            Ding currently supports Google Chrome, Microsoft Edge, and other
                            Chromium-based browsers. Unfortunately we don't support Safari or
                            Firefox.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            This is because Ding reads and saves files directly on your device, and
                            not all browsers support this level of access.
                        </Typography>
                    </>
                ),
            },
            {
                q: "Why do I have to choose a folder to open?",
                a: (
                    <Typography>
                        Ding is designed to work with collections of recordings, not individual
                        files. For example, you might have dozens of recordings as part of a
                        research project, and we assume you have them all stashed in the same
                        folder. Ding takes a folder-level view so you can easily jump between your
                        files.
                    </Typography>
                ),
            },
            {
                q: "Why is Ding asking to view and edit my files?",
                a: (
                    <Typography>
                        Because Ding reads and saves files on your device, you need to grant
                        permission to do so within the folders you open.
                    </Typography>
                ),
            },
            {
                q: "Why does Ding only support media files under 2GiB?",
                a: (
                    <>
                        <Typography>The 2GiB file size limit is due to the technical limitations
                            surrounding audio processing within the web browser.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>We're looking into work-arounds for this, but in the meantime you
                            could reduce the size of a file by re-encoding it, or extracting the audio
                            from a video file using an app such as Audacity.
                        </Typography>
                    </>
                ),
            },
        ],
    },
    {
        h: "Storage & Privacy",
        qa_list: [
            {
                q: "Where does Ding store my data?",
                a: (
                    <>
                        <Typography>
                            Ding stores notes and transcripts on your device, within the folder that
                            contains your recorded material.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            We figured that many recorded conversations are sensitive, confidential,
                            or subject to ethics considerations. We don't want the responsibility of
                            looking after your sensitive data! That's why we deisigned Ding to store
                            everything on your device.
                        </Typography>
                    </>
                ),
            },
            {
                q: "When and how does Ding handle my data on its servers?",
                a: (
                    <>
                        <Typography>
                            Your files are only ever uploaded to Ding's servers when you choose to
                            use the automated transcription service.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            We keep your files (and the transcriptions made from them) for up to 8
                            days, just in case you report a problem and we need to troubleshoot. We
                            intend to reduce this retention time as we gain confidence in our
                            systems. Files and transcriptions are automatically deleted from our
                            systems after 8 days.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            Our payments partner, Paddle, securely stores information related to
                            purchases and billing. You can read about their security measures
                            <a href="https://security.paddle.com/">here</a>.
                        </Typography>
                    </>
                ),
            },
            {
                q: "Am I responsible for backing up the files I use with Ding?",
                a: <Typography>Yes.</Typography>,
            },
            {
                q: "Can I move or rename my recordings?",
                a: (
                    <Typography>
                        No. Ding stores notes and transcripts with reference to the filename of the
                        recording they are associated with. We plan to add move and rename
                        functionality in the future.
                    </Typography>
                ),
            },
        ],
    },
    {
        h: "Collaboration & Sharing",
        qa_list: [
            {
                q: "Can I share or collaborate with others with Ding?",
                a: (
                    <Typography>
                        Ding doesn't have any collaboration functionality yet. If you need to work
                        with other people, you can share your Ding folders using a cloud storage app
                        like Dropbox or Google Drive. However, only one person at a time should
                        access a Ding folder, as concurrent editing by multiple users may result in
                        data loss.
                    </Typography>
                ),
            },
        ],
    },
    {
        h: "Transcription Service & Billing",
        qa_list: [
            {
                q: "How do I pay for transcription?",
                a: (
                    <Typography>
                        Ding offers a prepaid, per-minute AI transcription service. Transcription
                        credits can be purchased in the app. Upon payment, Ding will issue you a
                        credit code linked to the credits you paid for. The credit code will be
                        automatically added to the app. An invoice, and a copy of the credit code,
                        will be emailed to you for your records.
                    </Typography>
                ),
            },
            {
                q: "Can I share credit codes with other people or devices?",
                a: (
                    <Typography>
                        Yes. You may share credit codes (e.g. with colleagues), or use them on
                        multiple devices. Just remember that anyone you give the credit codes to
                        will have unlimited access to the value associated with them.
                    </Typography>
                ),
            },
            {
                q: "Do credit codes expire?",
                a: (
                    <Typography>
                        Yes. Credit codes expire one year after last use. Every time you order a
                        transcription, it resets the expiry clock on all credit codes loaded on your
                        device.
                    </Typography>
                ),
            },
            {
                q: "What is Paddle? Why was my card charged by them?",
                a: (
                    <>
                        <Typography>
                            Ding doesn't handle payments directly; we use a payments partner called
                            Paddle. They resell our products and handle the tedious side of business
                            for us, such as taxes, compliance, and payment systems security.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            Because of this, you will receive a receipt from Paddle and the
                            transaction will appear on your statement as "PADDLE.NET* DING".
                        </Typography>
                    </>
                ),
            },
            {
                q: "Do you offer refunds?",
                a: (
                    <>
                        <Typography>
                            We don't offer refunds for unused credits or unsatisfactory results.
                            Please remember that automatically generated transcriptions will not be
                            completely accurate.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            We will only consider refunds in exceptional cases.
                        </Typography>
                    </>
                ),
            },
        ],
    },
]
