import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import * as React from "react"

export default function TagListComponenet({ tagIndex, onTagClick }) {
    return (
        <Box
            sx={{
                width: 300,
                flexShrink: 0,
                borderRight: "solid 1px rgba(0, 0, 0, 0.12)",
                overflowY: "scroll",
                overflowX: "hidden",
            }}
        >
            <List dense={true} disablePadding>
                {Object.keys(tagIndex)
                    .sort()
                    .map((tag, index) => (
                        <ListItemButton
                            key={index}
                            onClick={(e) => onTagClick(e)}
                            label={tag}
                            data-tagname={tag}
                        >
                            <ListItemText sx={{ m: 0 }}>{tag}</ListItemText>
                            <Typography edge="end" variant="button" sx={{ color: "grey" }}>
                                <b>{tagIndex[tag].length}</b>
                            </Typography>
                        </ListItemButton>
                    ))}
            </List>
        </Box>
    )
}
