import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { type CheckoutOpenLineItem, type Paddle, initializePaddle } from "@paddle/paddle-js"
import * as React from "react"
import { usePaddleStore } from "../../model/PaddleStore"
import { Typography } from "@mui/material"

export default function Checkout({ onPaddleCheckoutComplete }) {

    // Create a local state to store Paddle instance
    const [paddle, setPaddle] = React.useState<Paddle>()

    const paddleEnv = usePaddleStore((state) => state.env)
    const paddleAuthToken = usePaddleStore((state) => state.authToken)
    const paddleProducts = usePaddleStore((state) => state.products)

    const onPaddleEvent = (event) => {
        if (event.name === "checkout.completed") {
            const txnId = event.data.transaction_id
            const customerEmail = event.data.customer.email
            onPaddleCheckoutComplete(txnId, customerEmail)
        }
    }

    // Download and initialize Paddle instance from CDN
    React.useEffect(() => {
        initializePaddle({ environment: paddleEnv, token: paddleAuthToken }).then(
            (paddleInstance: Paddle | undefined) => {
                if (paddleInstance) {
                    paddleInstance.Update({
                        eventCallback: onPaddleEvent,
                    })
                    setPaddle(paddleInstance)
                }
            },
        )
    }, [])

    // Callback to open a checkout
    const openCheckout = (priceId) => {
        const items: CheckoutOpenLineItem[] = [{
            priceId: priceId,
            quantity: 1
        }]
        paddle?.Checkout.open({
            items: items,
        })
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {paddleProducts.map((p) => (
                <Button
                    variant="outlined"
                    size="large"
                    key={p.name}
                    sx={{mb: 2, width: "50%"}}
                    onClick={() => {openCheckout(p.paddle_id)}}
                >
                    <Typography>{p.name}</Typography>
                    <Typography sx={{ ml: 'auto' }}>${p.price_usd}</Typography>
                </Button>
            ))}
        </Box>
    )
}
