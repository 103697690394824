import * as React from "react"

import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import ThemeProvider from "@mui/system/ThemeProvider"
import Typography from "@mui/material/Typography"
import { Outlet, useNavigate, useLocation } from "react-router"
import LocalStorage from "../../model/LocalStorage"
import EmptyMessage from "../Common/EmptyMessage"
import { Monitor } from "@phosphor-icons/react"
import { usePaddleStore } from "../../model/PaddleStore"

import "./app.css"

import MiniDrawer from "./Drawer.js"

export default function EditorApp({ theme }) {
    const [localStorage, setLocalStorage] = React.useState(new LocalStorage())
    const [canDoInitialRender, setCanDoInitialRender] = React.useState(false)
    const [directory, setDirectory] = React.useState()
    const [viewportTooSmall, setViewportTooSmall] = React.useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    React.useEffect( () => {
        if (directory == null && location.pathname !== "/app") {
            navigate("/app")
        }
    }, [navigate, directory, location])

    const checkWindowSize = () => {
        if (window.innerHeight < 400 || window.innerWidth < 900) setViewportTooSmall(true)
        else setViewportTooSmall(false)
    }
    window.addEventListener("resize", checkWindowSize)

    React.useEffect(checkWindowSize, [])

    const tryOpenPersistedBaseDir = async () => {
        const gotPersistedBaseDir = await localStorage.tryOpenPersistedBaseDir()
        if (gotPersistedBaseDir) {
            setDirectory(localStorage.getCurrentWorkingDirectory())
        }
        setCanDoInitialRender(true)
    }

    const onLoadFolderClicked = async (e) => {
        const gotStorageBaseDir = await localStorage.trySelectStorageBaseDir()
        if (gotStorageBaseDir) {
            setDirectory(localStorage.getCurrentWorkingDirectory())
            navigate("/app")
        }
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    React.useEffect(() => {
        tryOpenPersistedBaseDir()
    }, [])

    const onFolderClosed = (e) => {
        localStorage.onCloseDir()
        setDirectory(null)
        navigate("/app")
    }
    
    const initPaddle = usePaddleStore((state) => state.init)
    React.useEffect(() => {initPaddle()}, [initPaddle])

    if (!canDoInitialRender) {
        return null
    }

    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {viewportTooSmall ? (
                    <EmptyMessage
                        icon={<Monitor size={36} weight="bold" />}
                        message={
                            <Box sx={{ display:"flex", flexDirection:"column", alignItems:"center"}}>
                                <Typography variant="button"><b>Your screen is too small</b></Typography>
                                <Typography variant="button">Please resize this window or use a device with a bigger screen.</Typography>
                            </Box>
                        } />
                ) : (
                    <>
                        <Box width="100%" sx={{ display: "flex" }}>
                            {directory && (
                                <MiniDrawer
                                    localStorage={localStorage}
                                    onLoadFolderClicked={onLoadFolderClicked}
                                    onFolderClosed={onFolderClosed}
                                />
                            )}
                            <Box
                                component="main"
                                sx={{ flexGrow: 1, bgcolor: "background.default" }}
                            >
                                <Outlet context={[localStorage, directory, onLoadFolderClicked]} />
                            </Box>
                        </Box>
                    </>
                )}
            </ThemeProvider>
        </React.StrictMode>
    )
}
