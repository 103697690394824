import * as React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { TimeUtil } from "../../../model/TimeUtil"
import { CaretDown, Timer } from "@phosphor-icons/react"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { blue } from "@mui/material/colors"

export default function TimestampButton({ mediaCursorState, isEditing, updateTime, startTime }) {

    const [anchorEl, setAnchorEl] = React.useState(null)
    const timestampMenuOpen = Boolean(anchorEl)
    const timestampMenuClick = (event) => {
        if (!isEditing) return
        setAnchorEl(event.currentTarget)
    }

    const mediaCursor = React.useSyncExternalStore(
        mediaCursorState.subscribe,
        mediaCursorState.getSnapshot,
    )

    return (
        <>
            <Menu
                open={timestampMenuOpen}
                anchorEl={anchorEl}
                sx={{ marginTop: "2px" }}
                onClose={() => setAnchorEl(null)}
            >
                <MenuList dense sx={{ p: 0 }}>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            updateTime(mediaCursor.mediaCursor)
                        }}
                    >
                        <ListItemIcon>
                            <Timer size={16} weight="bold" />
                        </ListItemIcon>
                        <ListItemText>
                            Set time to {TimeUtil.secondsToDisplayFormat(mediaCursor.mediaCursor)}
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <Box>
                <Typography
                    sx={[
                        isEditing && { background: blue[100] },
                        isEditing && { "&:hover": { background: blue[200] } },
                    ]}
                    onClick={timestampMenuClick}
                    variant="caption"
                    style={{
                        marginRight: "6px",
                        marginBottom: "2px",
                        padding: "2px 4px 2px 4px",
                        opacity: "0.5",
                        fontWeight: "bold",
                        borderRadius: 4,
                        userSelect: "none",
                        paddingTop: "2px",
                        cursor: "pointer",
                    }}
                >
                    {TimeUtil.secondsToDisplayFormat(startTime)}
                    <CaretDown
                        weight="bold"
                        style={{
                            visibility: isEditing ? "visible" : "hidden",
                            marginLeft: "4px",
                            paddingTop: "1px",
                            verticalAlign: "text-top",
                        }}
                    />
                </Typography>
            </Box>
        </>
    )
}
