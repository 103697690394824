import axios from "axios"
import { creditCodeStore } from "./CreditCodeStore"
import { DTF } from "./dtf.mjs"

export class Job {
    mediaFilename // String
    jobId // String
    status // String: new, uploading, working, complete, failed
    progressPercent // Number
    uploadProgressPercent = 0 // Number, not serialized

    constructor(mediaFilename, jobId = null, status = null) {
        this.mediaFilename = mediaFilename
        this.jobId = jobId
        this.status = status
    }

    statusStringForUser = () => {
        switch (this.status) {
            case "created":
                return "Uploading"
            case "uploaded":
            case "converted":
            case "working":
            case "complete":
                return "Transcribing"
            case "collected":
                return "Done"
            case "failed":
                return "Failed"
            case "cancelled":
                return "Cancelled"
        }
    }

    static async create(mediaFilename) {
        const newJob = new Job(mediaFilename)
        await newJob.getNewJobID()
        return newJob
    }

    async getNewJobID() {
        const res = await fetch("/newJob")
        const newJobResponse = await res.json()
        const jobId = newJobResponse.jobId
        this.jobId = jobId
    }

    upload = async (localStorage, setUploadProgress, onUploadComplete) => {
        const formData = new FormData()
        const creditCodes = creditCodeStore.getState().codes
        formData.set("jobId", this.jobId)
        creditCodes.map((c) => formData.append("creditId[]", c))
        const fh = await localStorage.loadFileFromPath(this.mediaFilename)
        const f = await fh[0].getFile()
        formData.append("file", f)

        const uploadController = new AbortController()
        axios
            .request({
                method: "post",
                url: "/upload",
                data: formData,
                signal: uploadController.signal,
                onUploadProgress: (p) => {
                    setUploadProgress(this.jobId, (p.loaded / p.total) * 100)
                },
            })
            .then(onUploadComplete)
            .catch((error) => console.log(error))
        return uploadController
    }

    collect = async (localStorage) => {
        fetch(`/collectDTF/${this.jobId}`)
            .then((response) => response.text())
            .then(async (dtfString) => {
                const object = JSON.parse(dtfString)
                const dtf = DTF.fromJSON(object)
                await localStorage.updateDTF(this.mediaFilename, dtf)
            })
    }
}
