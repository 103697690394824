import { VttCue, WebVtt } from "@audapolis/webvtt-writer"
import { TimeUtil } from "./TimeUtil"
import { DTF, Sentence, VoicesTable } from "./dtf.mjs"

export class TranscriptLine {
    constructor(startTime, endTime, text, speakerId, speaker) {
        this.start = startTime
        this.end = endTime
        this.text = text
        this.speakerId = speakerId
        this.speaker = speaker

        this.startFormatted = TimeUtil.secondsToDisplayFormat(startTime)
    }

    clone() {
        return new TranscriptLine(this.start, this.end, this.text, this.speakerId, this.speaker)
    }
}

export class Transcript {
    content
    voices

    constructor(content, voices) {
        if (voices) {
            this.voices = voices
            for (const tl of content) {
                tl.speaker = voices[tl.speakerId]
            }
        }
        this.content = content
    }

    static fromDTF(dtf) {
        const content = []
        for (const s of dtf.sentences) {
            // const voice = s.voice ? dtf.voices.id_to_name_map[s.voice] : ""
            content.push(new TranscriptLine(s.start_ms/1000, s.end_ms/1000, s.text, s.voice))
        }
        return new Transcript(content, dtf.voices.id_to_name_map)
    }

    static toDTF(transcriptLines, voices) {
        const dtf = new DTF()
        dtf.setSentences(
            transcriptLines.map(tl => new Sentence(tl.text, tl.start*1000, tl.end*1000, tl.speakerId))
        )
        dtf.setVoices(new VoicesTable(voices))
        return dtf
    }

    static fromVTT(vttString) {
        return new Promise((resolve, reject) => {
            const content = []

            // eslint-disable-next-line no-undef
            const parser = new WebVTT.Parser(window, WebVTT.StringDecoder())
            parser.oncue = (cue) => {
                // Unsure how performant this is.
                // const fragment = cue.getCueAsHTML()
                // const speaker = fragment.querySelector("span").getAttribute("title")
                content.push(new TranscriptLine(cue.startTime, cue.endTime, cue.text))
            }
            parser.onparsingerror = (e) => {
                // TODO
            }
            parser.onflush = () => {
                // Completely parsed
                resolve(new Transcript(content))
            }
            parser.parse(vttString)
            parser.flush()
        })
    }

    // Content is array of TranscriptLine
    static toVTTString(content) {
        const vtt = new WebVtt()
        content.forEach((tsLine) => {
            vtt.add(
                new VttCue({
                    startTime: tsLine.start,
                    endTime: tsLine.end,
                    payload: tsLine.text,
                }),
            )
        })
        return vtt.toString()
    }
}
