import * as React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid2"
import TranscriptionStatus from "../../Common/TranscriptionStatus"

export default function TranscriptProcessing({job}) {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", p: 2 }}
        >
            <Box sx={{ width: "200px" }}>
                <TranscriptionStatus job={job} buttonSize="large" />
            </Box>
        </Grid>
    )
}