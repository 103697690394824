import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Divider from "@mui/material/Divider"
import MuiDrawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Typography from "@mui/material/Typography"
import ForgetCreditModal from "../Common/ForgetCreditModal"
import AddCreditModal from "./AddCreditModal"
import CreditPopover from "./CreditPopover"
import { useCreditCodeStore } from "../../model/CreditCodeStore"
import { PlusCircle } from "@phosphor-icons/react"
import { grey } from "@mui/material/colors"
import { styled, useTheme } from "@mui/material/styles"
import {
    CaretLeft,
    Folder,
    FolderOpen,
    List as ListIcon,
    Notebook,
    TagSimple,
    X,
} from "@phosphor-icons/react"
import * as React from "react"
import { Link as RouterLink } from "react-router"
import FileExplorerComponent from "./FileExplorerComponent"

const drawerWidth = 280

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
})

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }),
)

export default function MiniDrawer({ localStorage, onLoadFolderClicked, onFolderClosed }) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(true)
    
    const [creditPopoverAnchorEl, setCreditPopoverAnchorEl] = React.useState(null)

    const remainingMinutes = useCreditCodeStore( (state) => state.remainingCreditMinutes )
    const remainingCreditList = useCreditCodeStore( (state) => state.remainingCredits )
    const updateRemainingCredits = useCreditCodeStore( (state) => state.updateRemainingCredits)
    React.useEffect(() => {updateRemainingCredits()}, [updateRemainingCredits])

    const [anchorEl, setAnchorEl] = React.useState(null)
    const currentDirectroyPopoverOpen = Boolean(anchorEl)
    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleDrawerOpenClose = () => {
        setOpen(!open)
    }

    const handleCreditPopoverClick = (e) => {
        setCreditPopoverAnchorEl(e.currentTarget)
    }
    const handleCreditPopoverClose = () => {
        setCreditPopoverAnchorEl(null)
    }

    /**
     * Add Credit Modal
     */
    const [addCreditModalOpen, setAddCreditModalOpen] = React.useState(false)

    const handleAddCredit = () => {
        // updateRemainingCredit()
        setAddCreditModalOpen(false)
    }

    /**
     * Confirm Credit Remove AlertModal
     */
    const [confirmCreditRemoveModalOpen, setConfirmCreditRemoveModalOpen] = React.useState(false)
    const [creditIdToRemove, setCreditIdToRemove] = React.useState()

    const handleCreditRemove = (creditId) => {
        setConfirmCreditRemoveModalOpen(true)
        setCreditIdToRemove(creditId)
    }

    return (
        <Drawer
            variant="permanent"
            open={open}
            sx={{ display: "flex", height: "100%" }}
            PaperProps={{ sx: { backgroundColor: "#eaf0f6" } }}
        >

            <AddCreditModal
                isOpen={addCreditModalOpen}
                handleClose={() => setAddCreditModalOpen(false)}
                handleAddCredit={handleAddCredit}
            />
            <ForgetCreditModal
                isOpen={confirmCreditRemoveModalOpen}
                handleClose={() => setConfirmCreditRemoveModalOpen(false)}
                creditId={creditIdToRemove}
                remainingCreditList={remainingCreditList}
            />
            <CreditPopover
                creditPopoverAnchorEl={creditPopoverAnchorEl}
                handleCreditPopoverClose={handleCreditPopoverClose}
                remainingCreditList={remainingCreditList}
                handleCreditRemove={handleCreditRemove}
            />

            <DrawerHeader sx={{ backgroundColor: theme.palette.primary.main }}>
                <Typography
                    variant="h5"
                    sx={{
                        mr: "auto",
                        ml: 1,
                        color: theme.palette.grey[200],
                        fontWeight: 700,
                        visibility: open ? "visible" : "hidden",
                    }}
                >
                    DING!
                </Typography>
                <IconButton onClick={handleDrawerOpenClose}>
                    {!open && <ListIcon color={theme.palette.grey[200]} />}
                    {open &&
                        (theme.direction === "rtl" ? (
                            <CaretLeft color={theme.palette.grey[200]} />
                        ) : (
                            <CaretLeft
                                weight="bold"
                                size={16}
                                style={{ color: theme.palette.grey[200] }}
                            />
                        ))}
                </IconButton>
            </DrawerHeader>

            <List disablePadding>
                <ListItemButton sx={{ color: "primary.main" }} onClick={handlePopoverClick}>
                    <ListItemIcon sx={{ minWidth: 40, color: "primary.main" }}>
                        <FolderOpen size={24} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<b>{localStorage.storageBaseDir.name}</b>}
                        secondary={<Typography variant="overline" sx={{lineHeight: 1}}>Current Directory</Typography>}
                    />
                </ListItemButton>

                <Menu
                    open={currentDirectroyPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                >
                    <MenuList dense sx={{ p: 0 }}>
                        <MenuItem
                            onClick={() => {
                                handlePopoverClose()
                                onLoadFolderClicked()
                            }}
                        >
                            <ListItemIcon>
                                <Folder size={16} weight="bold" />
                            </ListItemIcon>
                            <ListItemText>Open other folder...</ListItemText>
                        </MenuItem>

                        <MenuItem onClick={onFolderClosed}>
                            <ListItemIcon>
                                <X size={16} weight="bold" />
                            </ListItemIcon>
                            <ListItemText>Close</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                <Link component={RouterLink} to="/app" underline="none">
                    <ListItemButton sx={{ color: grey[700] }}>
                        <ListItemIcon>
                            <Notebook size={24} />
                        </ListItemIcon>
                        <ListItemText>Overview</ListItemText>
                    </ListItemButton>
                </Link>
                <Link component={RouterLink} to="/app/tagindex" underline="none">
                    <ListItem disablePadding>
                        <ListItemButton sx={{ color: grey[700] }}>
                            <ListItemIcon>
                                <TagSimple size={24} />
                            </ListItemIcon>
                            <ListItemText>Tags</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>

            <Divider sx={{ mb: 1 }} />

            <FileExplorerComponent
                width={drawerWidth}
                localStorage={localStorage}
                sx={{
                    visibility: open ? "visible" : "hidden",
                    flexGrow: 1,
                    pt: 1,
                    height: "0px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    color: grey[700],
                }}
            />

            <Box
                sx={{
                    p:2,
                    visibility: open ? "visible" : "hidden",
                }}
                
            >
                <Typography variant="overline" sx={{ color: grey[700], userSelect: 'none'}}>
                    Transcription Credits
                </Typography>
                <ButtonGroup sx={{ display: "flex" }} variant="outlined" size="small" aria-label="Add credit">
                    <Button sx={{ flexGrow: 1 }} onClick={ (e) => handleCreditPopoverClick(e) }>
                        {remainingMinutes} minutes
                    </Button>
                    <Button
                        disableElevation
                        sx={{ ml: 2 }}
                        variant="contained"
                        onClick={ () => setAddCreditModalOpen(true) }
                        startIcon={<PlusCircle weight="bold" />}
                    >
                        Add credit
                    </Button>
                </ButtonGroup>
            </Box>
        </Drawer>
    )
}
