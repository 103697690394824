import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid2"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { grey } from "@mui/material/colors"
import { CaretDown, CheckCircle, Export, PencilSimpleLine, Note, UserSound } from "@phosphor-icons/react"
import * as React from "react"
import scrollIntoView from "scroll-into-view-if-needed"
import ExportTranscriptModal from "./ExportTranscriptModal"
import NoTranscript from "./NoTranscript"
import TranscriptProcessing from "./TranscriptProcessing"
import TranscriptLine from "./TranscriptLine"
import EditSpeakersModal from "./EditSpeakersModal"

/**
 * TODO: make this common with InterviewReadonly/TranscriptViewComponent
 */

function scrollTranscriptToCurrentLine() {
    const transcriptFuture = document.getElementsByClassName("transcript-future")[0]
    if (transcriptFuture) {
        const parentElement = document.getElementById("transcriptViewport")
        const scrollBehavior = isVisible(transcriptFuture, parentElement) ? "smooth" : "auto"
        scrollIntoView(transcriptFuture, { block: "nearest", behavior: scrollBehavior })
    }
}

function isVisible(ele, container) {
    // From https://phuoc.ng/collection/html-dom/check-if-an-element-is-visible-in-a-scrollable-container/
    if (!ele || !container) return false
    const { bottom, height, top } = ele.getBoundingClientRect()
    const containerRect = container.getBoundingClientRect()
    return top <= containerRect.top
        ? containerRect.top - top <= height
        : bottom - containerRect.bottom <= height
}

function Buttons({
    isEditing,
    onExportClicked,
    onSaveClicked,
    onEditClicked,
    onSpeakerNamesClicked,
    onCancelClicked,
    onAddNoteClicked,
    transcript,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const transcriptMenuOpen = Boolean(anchorEl)
    const transcriptMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <Grid container sx={{ p: 1 }}>
            <Menu
                open={transcriptMenuOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={() => setAnchorEl(null)}
            >
                <MenuList dense sx={{ p: 0 }}>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            onEditClicked()
                        }}
                    >
                        <ListItemIcon>
                            <PencilSimpleLine size={16} weight="bold" />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            onSpeakerNamesClicked()
                        }}
                    >
                        <ListItemIcon>
                            <UserSound size={16} weight="bold" />
                        </ListItemIcon>
                        <ListItemText>Speaker names...</ListItemText>
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            onExportClicked()
                        }}
                    >
                        <ListItemIcon>
                            <Export size={16} weight="bold" />
                        </ListItemIcon>
                        <ListItemText>Export...</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>

            <Grid container size="grow">
                <Button size="small" onClick={onAddNoteClicked} startIcon={<Note sixe={16} />}>
                    Add note
                </Button>
            </Grid>
            <Grid container>
                {isEditing && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ mr: 2, fontStyle: "italic", color: grey[400] }}>
                            Editing
                        </Typography>
                        <Button
                            sx={{ mr: 1 }}
                            size="small"
                            variant="outlined"
                            onClick={onCancelClicked}
                        >
                            Cancel
                        </Button>
                        <Button
                            disableElevation
                            size="small"
                            variant="contained"
                            onClick={onSaveClicked}
                            startIcon={<CheckCircle size={16} />}
                        >
                            Save
                        </Button>
                        <Divider orientation="vertical" sx={{ mx: 1 }} flexItem />
                    </Box>
                )}
                {transcript && (
                    <Button
                        size="small"
                        onClick={transcriptMenuClick}
                        endIcon={<CaretDown sixe={16} />}
                    >
                        Transcript
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

function Transcript({
    transcript,
    persist,
    mediaCursor,
    onTranscriptWordClick,
    isEditing,
}) {
    function calculateTranscriptLineClassName(t, index) {
        let className = t.start < mediaCursor.mediaCursorTimestamp ? "transcript-past" : "transcript-future"

        const nextTranscriptEntry = transcript[index + 1]
        const nextStartTime = nextTranscriptEntry ? nextTranscriptEntry.start : -1
        const nextClassName =
            nextStartTime < mediaCursor.mediaCursorTimestamp ? "transcript-past" : "transcript-future"

        if (className === "transcript-past" && nextClassName === "transcript-future")
            className = "transcript-current"

        if (className === "transcript-past" && index === transcript.length - 1)
            className = "transcript-current"

        return className
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <div
                id="transcriptViewport"
                style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                }}
            >
                <div style={{ maxHeight: "1px" }}>
                    {transcript.content.map((t, index, transcriptArray) => (
                        <TranscriptLine
                            t={t}
                            key={index}
                            className={calculateTranscriptLineClassName(t, index)}
                            index={index}
                            persist={persist}
                            isEditing={isEditing}
                            onTranscriptWordClick={onTranscriptWordClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default function TranscriptView({
    transcript,
    mediaCursorState,
    mediaDuration,
    onTranscribeClick,
    onTranscriptWordClick,
    onAddNoteClick,
    autoScroll,
    job,
    updateTranscript,
    filename,
}) {
    const [isEditing, setIsEditing] = React.useState(false)
    const editedTranscriptContent = React.useRef()

    const [isExportTranscriptModalOpen, setExportTranscriptModalOpen] = React.useState(false)
    const [isEditSpeakersModalOpen, setEditSpeakersModalOpen] = React.useState(false)

    const mediaCursor = React.useSyncExternalStore(mediaCursorState.subscribe, mediaCursorState.getSnapshot);

    const onEditClicked = () => {
        setIsEditing(true)
        editedTranscriptContent.current = transcript.content.map((t) => t.clone())
    }

    const onCancelClicked = () => {
        setIsEditing(false)
    }

    const onSaveClicked = () => {
        setIsEditing(false)
        updateTranscript(editedTranscriptContent.current, transcript.voices)
    }

    const persist = (index, text) => {
        editedTranscriptContent.current[index].text = text
    }

    const onExportClicked = () => {
        setExportTranscriptModalOpen(true)
    }

    const onSpeakerNamesClicked = () => {
        setEditSpeakersModalOpen(true)
    }

    if (autoScroll) {
        setTimeout(() => {
            scrollTranscriptToCurrentLine()
        }, 0)
    }

    /**
     * Do this when the transcript changes (ie, reset the component)
     */
    React.useEffect( () => {
        setIsEditing(false)
    }, [transcript])

    let content = null
    if (transcript) {
        content = (
            <Transcript
                persist={persist}
                isEditing={isEditing}
                transcript={transcript}
                filename={filename}
                mediaDuration={mediaDuration}
                mediaCursor={mediaCursor}
                updateTranscript={updateTranscript}
                onTranscriptWordClick={onTranscriptWordClick}
            />
        )
    } else if (job && job.status !== "cancelled" && job.status !== "failed") {
        content = <TranscriptProcessing job={job} />
    } else {
        content = (
            <NoTranscript onTranscribeClick={onTranscribeClick} />
        )
    }
    // TODO: Handle no transcript exists (eg. deleted), but job does

    return (
        <>
            <ExportTranscriptModal
                isOpen={isExportTranscriptModalOpen}
                handleClose={() => setExportTranscriptModalOpen(false)}
                filename={filename}
                transcript={transcript}
                mediaDuration={mediaDuration}
            />
            <EditSpeakersModal
                isOpen={isEditSpeakersModalOpen}
                handleClose={() => setEditSpeakersModalOpen(false)}
                transcript={transcript}
                updateTranscript={updateTranscript}
            />
            <Buttons
                isEditing={isEditing}
                onCancelClicked={onCancelClicked}
                onSaveClicked={onSaveClicked}
                onEditClicked={onEditClicked}
                onExportClicked={onExportClicked}
                onSpeakerNamesClicked={onSpeakerNamesClicked}
                onAddNoteClicked={onAddNoteClick}
                transcript={transcript}
            />

            {content}
        </>
    )
}
