import { Job } from "./Job"
import { useStore } from "zustand"
import { createStore } from "zustand/vanilla"
import { creditCodeStore } from "./CreditCodeStore"
import Pollinator from "pollinator"

const jobStore = createStore((set, get) => ({

    jobs: {},
    jobsByFilename: {},

    /**
     * Internal State
     */
    isStarted: false,
    abortControllers: {},   // JobID -> AbortController (for active upload)
    localStorage: undefined,
    poller: undefined,

    load: async (localStorage) => {
        // Cleanup (eg. when opening a new directory)
        get().stopPolling()

        const jobs = {}
        set({localStorage: localStorage})

        // Always poll for updates every 5sec
        const poller = new Pollinator(get().checkForUpdates, { delay: 5000 })
        poller.on(Pollinator.Event.POLL, get().onUpdate)
        poller.start()
        set({poller: poller})

        // TODO: proper deserialization function
        // TODO: check state of deserialized jobs, eg, retry upload

        const input = await get().localStorage.getJobManagerJson()

        for (const [key, value] of Object.entries(input)) {
            const job = new Job(value.mediaFilename, value.jobId, value.status)
            if (value.status === "created") {
                // Upload did not complete
                job.status = "failed"
            }
            set( state => ({
                jobs: {
                    ...state.jobs,
                    [job.jobId]: job
                }
            }))
        }
    },

    checkForUpdates: () => {
        const jobs = Object.values(get().jobs)
        if (jobs.length === 0) return
        const jobIdsToCheck = jobs
            .filter(
                (j) =>
                    !j.status ||
                    (j.status !== "collected" && j.status !== "failed" && j.status !== "cancelled"),
            )
            .map((j) => j.jobId)
        if (jobIdsToCheck.length === 0) return
        return fetch(`/jobStatus/${jobIdsToCheck.join(",")}`)
    },

    onUpdate: async (res) => {
        if (!res) return
        const result = await res.json()
        for (const [jobId, statusResult] of Object.entries(result)) {
            get().setStatus(jobId, statusResult.status)
            if (statusResult.status === "complete") {
                get().jobs[jobId].collect(get().localStorage)
            }
            if (statusResult.status === "failed") {
                creditCodeStore.getState().updateRemainingCredits()
            }
        }
    },

    setStatus: (jobId, status) => {
        if (get().jobs[jobId]) {
            set( state => ({
                jobs: {
                    ...state.jobs,
                    [jobId]: {
                        ...state.jobs[jobId],
                        status: status
                    }
                }
            }))
        } 
        get().saveJobs()
    },

    saveJobs: () => {
        get().localStorage.saveJobs(get().serialize())
    },

    serialize: () => {
        return JSON.stringify(get().jobs)
    },

    stopPolling() {
        get().poller?.stop()
    },

    setUploadProgress: (jobId, uploadProgressPercent) => {
        set( state => ({
            jobs: {
                ...state.jobs,
                [jobId]: {
                    ...state.jobs[jobId],
                    uploadProgressPercent: uploadProgressPercent
                }
            }
        }))
    },

    setUploadComplete(jobId) {
        get().setStatus(jobId, "uploaded")
        creditCodeStore.getState().updateRemainingCredits()
    },

    add: async (mediaFilename) => {
        const newJob = await Job.create(mediaFilename)
        if (!newJob) {
            alert("Could not start transcription.")
            // TODO: give reason
            return
        }
        const abortController = await newJob.upload(
            get().localStorage,
            get().setUploadProgress,
            () => get().setUploadComplete(newJob.jobId),
        )
        set( state => ({
            jobs: {
                ...state.jobs,
                [newJob.jobId]: newJob
            },
            abortControllers: {
                ...state.abortControllers,
                [newJob.jobId]: abortController
            }
        }))
        get().saveJobs()
    },

    remove: (jobId) => {
        set(state => ({
            jobs: state.jobs.filter( (j) => j.jobId !== jobId )
        }))
        get().saveJobs()
    },

    cancelUpload(jobId) {
        get().abortControllers[jobId].abort()
        get().setStatus(jobId, "cancelled")
    }

}))

const useJobStore = (selector) => useStore(jobStore, selector)
export { jobStore, useJobStore }
