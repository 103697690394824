import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import * as React from "react"
import HomepageOutline from "./HomepageOutline"

export default function AboutPage({ theme }) {
    return (
        <HomepageOutline theme={theme}>
            <Grid container spacing={16}>
                <Grid size={3}>
                    <Avatar src="/img/bio.jpg" sx={{ width: "200px", height: "200px" }} />
                </Grid>
                <Grid size={9}>
                    <Typography variant="h6">Hi, I'm Mitch.</Typography>
                    <br />

                    <Typography variant="h6">
                        There's immense value in sitting down and listening back to conversations.
                        It helps thoughtful humans comprehend things in nuanced ways that AI summaries
                        cannot. Ding is a tool to make that process as easy as possible.
                    </Typography>
                    <br />

                    <Typography variant="h6">
                        I made Ding to help manage hundreds of hours of video interviews. I
                        wanted to quickly jump between them while synthesizing important pieces of
                        conversation. I wanted a transcription service without subscription
                        fees or monthly limits.
                    </Typography>
                    <br />

                    <Typography variant="h6">
                        Today, Ding helps me organise the interviews I do as a freelance writer.
                        I'm sharing Ding to help you with your own writing and research. Please
                        drop me a line with suggestions for how Ding! can be more helpful for you.
                    </Typography>
                </Grid>
            </Grid>
        </HomepageOutline>
    )
}
