import { Button } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import { blueGrey } from "@mui/material/colors"
import { Play, TagSimple } from "@phosphor-icons/react"
import * as React from "react"
import { useOutletContext } from "react-router"
import Tags from "../../model/Tags.js"
import { TimeUtil } from "../../model/TimeUtil"
import VideoJS from "../VideoJSComponent/VideoJSComponent"
import TagListComponenet from "./TagListComponenet.js"
import EmptyMessage from "../Common/EmptyMessage.js"

export default function TagIndex() {
    const [localStorage] = useOutletContext()

    const [tagIndex, setTagIndex] = React.useState({})
    const [selectedTag, setSelectedTag] = React.useState({})

    const [startTime, setStartTime] = React.useState(0)
    const [selectedMedia, setSelectedMedia] = React.useState(null)
    const [vjsTrigger, setVjsTrigger] = React.useState(0) // A hack to trigger an effect (play at timestamp) on the videojs componenet without
    // any other state change

    React.useEffect(() => {
        const loadTags = async () => {
            const tagIndex = await Tags.createTagIndex(localStorage)
            setTagIndex(tagIndex)
        }
        loadTags()
    }, [])

    const onTagClick = (e) => {
        const tag = e.target.closest("[data-tagname]").getAttribute("data-tagname")
        setSelectedTag(tagIndex[tag])
    }

    const onCommentPlayClick = async (e) => {
        const mediasrc = e.target.closest("[data-mediasrc]").getAttribute("data-mediasrc")
        const startTime = e.target.closest("[data-starttime]").getAttribute("data-starttime")

        const selectedMedia = localStorage.getMediaFile(mediasrc)

        setSelectedMedia(selectedMedia)
        setStartTime(startTime)
        setVjsTrigger(vjsTrigger + 1)
    }

    return (
        <Box style={{ display: "flex", height: "100%", maxWidth: "100%" }}>
            <TagListComponenet tagIndex={tagIndex} onTagClick={onTagClick} />

            {Object.keys(tagIndex).length > 0 ? (
                <Grid container sx={{ flexGrow: 1, my: 2 }}>
                    <Grid
                        size={7}
                        sx={{ px: 4 }}
                        container
                        direction="column"
                        style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}
                    >
                        <div>
                            {Object.values(selectedTag).map((annotation, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        p: 1,
                                        mb: 2,
                                        border: 1,
                                        borderColor: "grey.200",
                                        borderRadius: 1,
                                        backgroundColor: "grey.50",
                                    }}
                                >
                                    <Button
                                        data-mediasrc={annotation.mediaFile.filename}
                                        data-starttime={annotation.start}
                                        variant="outlined"
                                        onClick={(e) => onCommentPlayClick(e)}
                                        startIcon={<Play size={16} weight="fill" />}
                                        size="small"
                                    >
                                        {annotation.mediaFile.basename()} &nbsp;{" "}
                                        <span style={{ color: blueGrey[300] }}>
                                            @{TimeUtil.secondsToDisplayFormat(annotation.start)}
                                        </span>
                                    </Button>
                                    <Typography mt={1}>{annotation.comment}</Typography>
                                    <Typography variant="caption" style={{ color: blueGrey[300] }}>
                                        {annotation.tags.join(", ")}
                                    </Typography>
                                </Box>
                            ))}
                        </div>
                    </Grid>

                    <Grid size={5} sx={{ pr: 4 }} container direction="column">
                        <VideoJS
                            vjsTrigger={vjsTrigger}
                            onCursorMove={() => {}}
                            media={selectedMedia}
                            setTranscriptAutoScroll={() => {}}
                            startTime={startTime}
                        />
                    </Grid>
                </Grid>
            ) : (
                <EmptyMessage
                    icon={<TagSimple size={36} weight="bold" />}
                    message={
                        <Box
                            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                        >
                            <Typography variant="button">
                                <b>No tags... yet!</b>
                            </Typography>
                            <Typography variant="button">
                                Add tags to your comments to make an index of the topics you care
                                about.
                            </Typography>
                        </Box>
                    }
                />
            )}
        </Box>
    )
}
