import * as React from "react"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import WaveformData from "waveform-data"
import { grey } from "@mui/material/colors"

export default function PeakGeneratorComponent({ mediaFile, localStorage }) {
    // Make sure only called once, especially in StrictMode
    const started = React.useRef(false)

    const [currentFile, setCurrentFile] = React.useState("")

    const generatePeaks = async () => {
        setCurrentFile(mediaFile.fileHandle.name)

        const file = await mediaFile.fileHandle.getFile()
        const buffer = await file.arrayBuffer()

        const audioContext = new AudioContext({
            sampleRate: 8000,
        })

        const options = {
            audio_context: audioContext,
            array_buffer: buffer,
            scale: 128,
        }

        const waveformdata = await new Promise((resolve, reject) => {
            WaveformData.createFromAudio(options, (err, waveform) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(waveform)
                }
            })
        })

        await localStorage.writePeaks(mediaFile, waveformdata.toJSON())
    }

    React.useEffect(() => {
        if (started.current) return
        else {
            started.current = true
            generatePeaks()
        }
    }, [])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%" }}
        >
            <Button
                disabled
                startIcon={
                    <CircularProgress
                        color={grey[400]}
                        size={12}
                        thickness={6}
                        style={{ marginLeft: "12px", marginRight: "12px" }}
                    />
                }
                size="large"
                variant="outlined"
            >
                Loading audio
            </Button>

            <Typography color={grey[400]} sx={{ mt: 4 }}>
                {currentFile}
            </Typography>
        </Grid>
    )
}
