import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Tooltip from "@mui/material/Tooltip"
import { grey } from "@mui/material/colors"
import { CaretDown, CaretRight, FileAudio, FileVideo } from "@phosphor-icons/react"
import * as React from "react"
import { useLocation, useNavigate } from "react-router"

function FilesystemItem({
    entry,
    index,
    sx = { pl: 2, py: "2px" },
    currentFilePath,
    openTrigger,
    display = "block",
}) {
    const defaultOpen = currentFilePath && currentFilePath.startsWith(entry.filePath)
    const [isOpen, setIsOpen] = React.useState(defaultOpen)
    const [instanceOpenTrigger, setInstanceOpenTrigger] = React.useState(false)
    const navigate = useNavigate()

    if (defaultOpen && !isOpen && openTrigger !== instanceOpenTrigger) {
        setIsOpen(true)
    }
    if (openTrigger !== instanceOpenTrigger) {
        setInstanceOpenTrigger(!instanceOpenTrigger)
    }

    return (
        <Box
            sx={{
                backgroundColor: entry.filePath === currentFilePath ? grey[200] : "",
                display: display,
            }}
        >
            <Tooltip title={entry.filePath} placement="right">
                <ListItemButton
                    disableGutters
                    sx={sx}
                    key={index}
                    onClick={() => {
                        if (entry.kind === "directory") setIsOpen(!isOpen)
                        else navigate(`/app/explorer/${entry.filePath}`)
                    }}
                >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                        {entry.iconType === "directory" &&
                            (isOpen ? <CaretDown /> : <CaretRight />)}
                        {entry.iconType === "audio" && <FileAudio />}
                        {entry.iconType === "video" && <FileVideo />}
                    </ListItemIcon>
                    <ListItemText
                        sx={{ m: 0 }}
                        primary={entry.name}
                        primaryTypographyProps={{
                            fontWeight: entry.filePath === currentFilePath ? "bold" : "",
                        }}
                    />
                </ListItemButton>
            </Tooltip>
            {isOpen && entry.children &&
                entry.children.map((e, index) => (
                    <FilesystemItem
                        display={isOpen ? "block" : "none"}
                        entry={e}
                        key={index}
                        openTrigger={openTrigger}
                        sx={{ pl: sx.pl + 1, py: sx.py }}
                        currentFilePath={currentFilePath}
                    />
                ))}
        </Box>
    )
}

export default function FileExplorerComponent({ sx, localStorage }) {
    const [directoryTree, setDirectoryTree] = React.useState([])
    const [currentDirectory, setCurrentDirectory] = React.useState()
    const [currentFile, setCurrentFile] = React.useState("")
    const [openTrigger, setOpenTrigger] = React.useState(true) // Must be opposite FilesystemItem default
    const allMediaFiles = React.useSyncExternalStore(
        localStorage.subscribe,
        localStorage.getSnapshot,
    )

    const location = useLocation()
    React.useEffect(() => {
        const filename = location.pathname.split("/explorer/")[1]
        setCurrentFile(filename)
        setOpenTrigger(!openTrigger)
    }, [location])

    const loadDirectory = async () => {
        const entries = await localStorage.directoryTree()
        setCurrentDirectory(localStorage.storageBaseDir)
        setDirectoryTree(entries)
    }

    React.useEffect(() => {
        if (localStorage.storageBaseDir !== currentDirectory) {
            setDirectoryTree([])
        }
        loadDirectory()
    }, [allMediaFiles])

    return (
        <Box sx={sx}>
            <List dense={true} disablePadding>
                {directoryTree.map((e, index) => (
                    <FilesystemItem
                        entry={e}
                        key={index}
                        openTrigger={openTrigger}
                        currentFilePath={currentFile}
                    />
                ))}
            </List>
        </Box>
    )
}
