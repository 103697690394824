import * as React from "react"
import Box from "@mui/material/Box"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid2"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import ContentEditable from "../../Common/ContentEditablePlaintext.tsx"
import { grey } from "@mui/material/colors"
import TimestampButton from "./TimestampButton.js"

export default function AnnotationEditor({
    selectedAnnotation,
    allTags,
    mediaCursorState,
    onCancelClicked,
    onDeleteClicked,
    onSaveClicked,
    onTimecodeClick,
    index,
    isEditing,
}) {
    const [annotation, setAnnotation] = React.useState(selectedAnnotation)

    function onTagsChange(event, selectedTags) {
        // We added an empty string tag at the start which we use to render the timestamp chip
        // Filter it out before saving
        const filteredTags = selectedTags.filter((t) => t)
        setAnnotation({
            ...annotation,
            tags: filteredTags,
        })
    }

    function onCommentChange(event) {
        const comment = event.target.value
        setAnnotation({
            ...annotation,
            comment: comment,
        })
    }

    function updateTime(startTime) {
        setAnnotation({
            ...annotation,
            start: startTime // mediaCursor.mediaCursor,
        })
    }

    const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
        "& .MuiFormControl-root": {
            backgroundColor: "transparent",
        },
        "& .MuiInput-root::before": {
            border: 0,
        },
        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
            border: 0,
        },
        "& .MuiInput-root:hover::before": {
            border: 0,
        },
        "& .MuiInput-root::after": {
            border: 0,
        },
        "& .MuiAutocomplete-root:hover::after": {
            border: 0,
        },
        "& .MuiInputBase-input": {
            paddingBottom: "0 !important",
            fontSize: "14px",
            marginLeft: "8px",
            cursor: isEditing ? "" : "pointer",
        },
        "& .MuiAutocomplete-root": {
            cursor: isEditing ? "" : "pointer",
        },
        "& .MuiInputBase-root": {
            cursor: isEditing ? "" : "pointer",
        },
    }))

    function onClick(e) {
        if (!isEditing) {
            onTimecodeClick(e)
        }
    }

    const renderChip = (option, props) => {
        const { key, ...rest } = props
        return(
            <Chip
                size="small"
                label={option}
                key={option}
                {...rest}
                sx={{
                    m: 0,
                    backgroundColor: isEditing ? grey[200] : grey[100],
                    color: isEditing ? grey[800] : grey[600],
                }}
            />
        )
    }

    return (
        <Box
            className={`annotation ${isEditing && "isEditing"}`}
            onClick={onClick}
            data-timecode={annotation.start}
            data-index={index}
            sx={{
                p: 1,
                cursor: isEditing ? "" : "pointer",
                backgroundColor: isEditing ? grey[100] : "",
                border: 1,
                borderRadius: 1,
                borderColor: isEditing ? grey[300] : "#ffffff00",
                mb: 1,
            }}
        >
            <Grid container direction="row">
                <Grid>
                    <TimestampButton
                        mediaCursorState={mediaCursorState}
                        isEditing={isEditing}
                        updateTime={updateTime}
                        startTime={annotation.start}
                    />
                </Grid>
                <Grid size="grow">
                    <StyledAutocomplete
                        fullWidth
                        size="small"
                        multiple
                        freeSolo
                        clearOnBlur
                        readOnly={!isEditing}
                        options={allTags}
                        value={annotation.tags || []}
                        onChange={onTagsChange}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder={isEditing ? "Add (more) tags..." : ""}
                            />
                        )}
                        renderTags={(tagValues, getTagProps) =>
                            tagValues.map((option, index) => (
                                renderChip(option, getTagProps({index}))
                            ))
                        }
                    />
                </Grid>
            </Grid>

            <ContentEditable
                html={annotation.comment}
                onChange={onCommentChange}
                style={{
                    background: isEditing ? grey[200] : "",
                    outline: "none",
                    padding: "8px",
                    borderRadius: "4px",
                    whiteSpace: "pre-wrap",
                }}
                disabled={!isEditing}
            />

            {isEditing && (
                <Grid container sx={{ mt: 1, justifyContent: "end" }}>
                    <Button sx={{ mr: 1 }} size="small" onClick={onCancelClicked} disableElevation>
                        Cancel
                    </Button>
                    {!annotation.isNew ? (
                        <Button
                            sx={{ mr: 1 }}
                            size="small"
                            onClick={onDeleteClicked}
                            disableElevation
                        >
                            Delete
                        </Button>
                    ) : null}
                    <Button
                        disableElevation
                        size="small"
                        onClick={() => onSaveClicked(annotation)}
                        variant="contained"
                    >
                        Save
                    </Button>
                </Grid>
            )}
        </Box>
    )
}
