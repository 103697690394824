/**
 * Use an External Store to store the videojs media cursor position.
 * This prevents unnecessary rendering when passing the media cursor
 * position via traditional state.
 */
export class MediaCursorState {

    snapshot = {
        mediaCursor: 0,
        mediaCursorTimestamp: 0
    }
    listeners = []

    set = (time) => {
        this.snapshot = {
            mediaCursor: time.toFixed(2),
            mediaCursorTimestamp: time
        }
        this.notifyListeners()
    }

    subscribe = (listener) => {
        this.listeners = [...this.listeners, listener]
        return () => {
            this.listeners = this.listeners.filter((l) => l !== listener)
        }
    }

    getSnapshot = () => {
        return this.snapshot
    }

    notifyListeners() {
        for (const listener of this.listeners) {
            listener()
        }
    }
}