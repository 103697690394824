import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Empty } from "@phosphor-icons/react"
import * as React from "react"
import FileTable from "./FileTable"
import EmptyMessage from "../Common/EmptyMessage.js"

export default function FolderOverviewComponent({ localStorage }) {

    const allMediaFiles = React.useSyncExternalStore(
        localStorage.subscribe,
        localStorage.getSnapshot,
    )

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {Object.keys(allMediaFiles).length > 0 ? (
                <FileTable
                    mediaFiles={allMediaFiles}
                />
            ) : (
                <EmptyMessage
                    icon={<Empty size={36} weight="bold" />}
                    message={
                        <Typography variant="button">Ding found no media within this folder</Typography>
                    }
                />
            )}
        </Box>
    )
}
