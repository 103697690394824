import LocalStorage from "./LocalStorage"

export class Annotation {
    // {
    //     "start":"1371.23",
    //     "comment":"Maurice Connors another woman after their mother, who Phyllis met at Rocky Creek Hospital.",
    //     "tags":[]
    // }

    static reactKeyCounter = 0

    start
    comment
    tags = []
    reactKey

    constructor({ start, comment, tags }) {
        this.start = start
        this.comment = comment || ""
        this.tags = tags || []
        this.reactKey = Annotation.reactKeyCounter++
    }

    // TODO: remove
    static async readFromFile(annotationsFileHandle) {
        if (annotationsFileHandle === null) {
            return []
        }
        const annotationsFile = await annotationsFileHandle.getFile()
        const annotationsFileData = await LocalStorage.readFileAsync(annotationsFile)

        if (annotationsFileData === "") {
            return []
        }
        const fileContents = JSON.parse(annotationsFileData)
        const annotations = fileContents.map((a) => new Annotation(a))
        return annotations
    }

    // TODO: remove
    static stripToPersist(annotations) {
        return annotations.map((a) => {
            return {
                start: a.start,
                comment: a.comment,
                tags: a.tags,
            }
        })
    }

    static fromJSON(object) {
        return new Annotation({
            start: object.start,
            comment: object.comment,
            tags: object.tags
        })
    }

    toJSON() {
        return {
            start: this.start,
            comment: this.comment,
            tags: this.tags,
        }
    }
}
