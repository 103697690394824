import { Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid2"
import { grey } from "@mui/material/colors"
import {  Waveform } from "@phosphor-icons/react"
import * as React from "react"

export default function NoTranscript({onTranscribeClick}) {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: "100%",
                p: 2,
            }}
        >
            <Grid>
                <Button
                    startIcon={<Waveform />}
                    onClick={onTranscribeClick}
                    size="large"
                    variant="outlined"
                >
                    Transcribe
                </Button>
            </Grid>
            <Grid sx={{ mt: 4, color: grey[400] }}>
                <Typography>
                    Add a transcription to rapidly comprehend and navigate conversations.
                </Typography>
            </Grid>
        </Grid>
    )
}
