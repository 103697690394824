import * as Sentry from "@sentry/react"
import { createTheme } from "@mui/material/styles"
import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter, Outlet } from "react-router"
import "./titillium.css"
import ErrorElement from "./components/ErrorElement"
import EditorAppErrorElement from "./components/EditorApp/EditorAppErrorElement"
import DingScreen from "./components/DingScreen/DingScreen"
import EditorApp from "./components/EditorApp/EditorApp"
import AboutPage from "./components/Homepage/AboutPage.js"
import HelpPage from "./components/Homepage/HelpPage.js"
import Homepage from "./components/Homepage/Homepage"
import PricingPage from "./components/Homepage/PricingPage.js"
import PrivacyPage from "./components/Homepage/PrivacyPage.js"
import TermsPage from "./components/Homepage/TermsPage.js"
import Interview from "./components/Interview/Interview.js"
import TagIndex from "./components/TagIndex/TagIndex"
import reportWebVitals from "./reportWebVitals"

Sentry.init({
    dsn: "https://16ccf29d46bce5ad9c643538867192a6@o4508617071919104.ingest.de.sentry.io/4508617148268624",
    environment: process.env.NODE_ENV,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/dingnotes\.com\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: (event) => {
        if (event.transaction?.startsWith("/app/explorer")) {
            event.transaction = "/app/explorer" // Scrub the filename
            // biome-ignore lint/performance/noDelete: <explanation>
            delete event.request
        }
        return event
    },
})

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'ui-sans-serif', 'system-ui',
            "Asap",
            // 'Titillium Web',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})

const router = createBrowserRouter(
    [
        {
            element: (
                <>
                    <Outlet />
                </>
            ),
            errorElement: <ErrorElement theme={theme} />,
            children: [
                {
                    path: "/",
                    element: <Homepage theme={theme} />,
                },
                {
                    path: "/about",
                    element: <AboutPage theme={theme} />,
                },
                {
                    path: "/help",
                    element: <HelpPage theme={theme} />,
                },
                {
                    path: "/pricing",
                    element: <PricingPage theme={theme} />,
                },
                {
                    path: "/terms",
                    element: <TermsPage theme={theme} />,
                },
                {
                    path: "/privacy",
                    element: <PrivacyPage theme={theme} />,
                },
                {
                    path: "/app",
                    element: <EditorApp theme={theme} />,
                    errorElement: <EditorAppErrorElement theme={theme} />,
                    children: [
                        {
                            path: "",
                            element: <DingScreen />,
                        },
                        {
                            path: "tagindex",
                            element: <TagIndex />,
                        },
                        {
                            path: "explorer/*",
                            children: [
                                {
                                    path: "*",
                                    element: <Interview />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    {},
)

ReactDOM.createRoot(document.getElementById("root")).render(<RouterProvider router={router} />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
