/**
 * DTF, the Ding Transcript Format
 * Copied from the server folder (MAKE SURE to keep in sync)
 */

class Sentence {
    text
    start_ms
    end_ms
    voice
    
    constructor(text, start_ms, end_ms, voice) {
        this.text = text
        this.start_ms = start_ms
        this.end_ms = end_ms
        if (voice) this.voice = voice
    }

    static fromJSON(object) {
        const sentence = new Sentence(
            object.text ?? object.text,
            object.start_ms ?? object.start_ms,
            object.end_ms ?? object.end_ms,
            object.voice ?? object.voice
        )
        return sentence
    }

    toJSON() {
        const out = {
            text: this.text,
            start_ms: this.start_ms,
            end_ms: this.end_ms,
        }
        if (this.voice) out.voice = this.voice
        return out
    }
}

class VoicesTable {

    id_to_name_map

    constructor(id_to_name_map) {
        this.id_to_name_map = id_to_name_map
    }

    static fromJSON(object) {
        const voicesTable = new VoicesTable()
        voicesTable.id_to_name_map = object.id_to_name_map ?? object.id_to_name_map
        return voicesTable
    }

    toJSON() {
        return {
            id_to_name_map: this.id_to_name_map
        }
    }
}

class DTF {

    dtf_version = 1
    sentences = []
    voices

    setSentences(sentences) {
        this.sentences = sentences
        return this
    }

    setVoices(voices) {
        this.voices = voices
        return this
    }

    static fromJSON(object) {
        const dtf = new DTF()
        dtf.dtf_version = 1
        if (object.sentences)
            dtf.sentences = object.sentences.map( (s) => Sentence.fromJSON(s) )
        if (object.voices)
            dtf.voices = VoicesTable.fromJSON(object.voices)
        return dtf
    }

    toJSON() {
        const out = {
            dtf_version: this.dtf_version,
            sentences: this.sentences.map( (s) => s.toJSON() ),
        }
        if (this.voices)
            out.voices = this.voices
        return out
    }
}

export {
    DTF,
    Sentence,
    VoicesTable
}