import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import OutlinedInput from "@mui/material/OutlinedInput"
import Grid from "@mui/material/Grid2"
import { CheckCircle, UserCircle } from "@phosphor-icons/react"
import * as React from "react"
import AlertModal from "../../Common/AlertModal"
import { grey } from "@mui/material/colors"

export default function EditSpeakersModal({ isOpen, handleClose, transcript, updateTranscript }) {

    const voices = React.useRef()
    voices.current = transcript?.voices

    if (!transcript)
        return null

    const setVoice = (id, voice) => {
        voices.current[id] = voice
    }

    const onSaveClicked = () => {
        updateTranscript(transcript.content, voices.current)
        handleClose()
    }

    const message = (
        <Grid container size="grow" direction="column" sx={{ pt: 3, px: 8 }}>

            {transcript.voices && Object.entries(transcript.voices).map( ([id, voiceName]) => (
                <OutlinedInput
                    size="small"
                    key={id}
                    id={id}
                    defaultValue={voiceName}
                    onChange={ (e) => { setVoice(id, e.target.value) }}
                    sx={{mb:3}}
                    startAdornment={<UserCircle size={24} weight="bold" color={grey[500]} style={{marginRight: "12px"}} />}
                >
                </OutlinedInput>
            ) )}

            
        </Grid>
    )

    const buttons = (
        <Box sx={{ py: 3, px: 3, display: "flex", flexDirection: "row" }}>
            <Grid sx={{ flexGrow: 1 }}></Grid>
            <Grid>
                <Button disableElevation variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
                    Cancel
                </Button>
                <Button
                    disableElevation
                    variant="contained"
                    startIcon={<CheckCircle />}
                    onClick={onSaveClicked}
                >
                    Save
                </Button>
            </Grid>
        </Box>
    )

    return (
        <AlertModal
            isOpen={isOpen}
            handleClose={handleClose}
            width="400px"
            height="90%"
            minWidth="600px"
            message={message}
            buttons={buttons}
            title="Speaker Names"
        />
    )
}
