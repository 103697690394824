import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid2"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { Receipt, X } from "@phosphor-icons/react"
import * as React from "react"
import { useCreditCodeStore } from "../../model/CreditCodeStore"
import Checkout from "../Payment/Paddle.tsx"
import CreditReceipt from "./CreditReceipt"

export default function AddCreditFlow({ handleClose, onCreditAdded }) {
    const [transactionId, setTransactionId] = React.useState()
    const [creditCode, setCreditCode] = React.useState()
    const [creditMinutes, setCreditMinutes] = React.useState()
    const [customerEmail, setCustomerEmail] = React.useState()
    const addCreditCodeToStore = useCreditCodeStore( (state) => state.addCreditCode)

    const addCreditCode = (creditCodes) => {
        // TODO: sanitize/parse/verify
        addCreditCodeToStore(creditCodes)
        onCreditAdded()
    }

    const onPaddleCheckoutComplete = (transactionId, customerEmail) => {
        setTransactionId(transactionId)
        setCustomerEmail(customerEmail)

        fetch(`/paddle/claimTxn/${transactionId}`)
            .then((response) => response.json())
            .then((claimTxnResponse) => {
                addCreditCodeToStore(claimTxnResponse.creditId)
                setCreditCode(claimTxnResponse.creditId)
                setCreditMinutes(claimTxnResponse.minutes)
            })
    }

    const header = (
        <Box
            sx={{ px: 3, py: 2, backgroundColor: grey[200], display: "flex", alignItems: "center" }}
        >
            <h2 style={{ margin: 0, lineHeight: 1, flexGrow: 1 }}>Add Transcription Credit</h2>
            <IconButton onClick={handleClose}>
                <X size={20} weight="bold" />
            </IconButton>
        </Box>
    )

    const checkoutComplete = () => {
        return (
            <Box>
                {header}
                <Divider />
                <Box>
                    <Box>
                        <CreditReceipt creditCode={creditCode} creditMinutes={creditMinutes} />

                        <Typography sx={{ px: 3 }}>
                            This code has been stored for use on this browser. A copy has been sent
                            to {customerEmail}. You may share or use this code on other devices.
                            Credit codes expire one year after they are last used.
                        </Typography>

                        <Box
                            sx={{
                                p: 3,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                            }}
                        >
                            <Button disableElevation variant="outlined" onClick={onCreditAdded}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    const hasTransactionId = () => {
        return (
            <Box>
                {header}
                <Divider />
                <Grid container sx={{ justifyContent: "center" }}>
                    <Button
                        sx={{ m: 6 }}
                        startIcon={<CircularProgress size={16} thickness={6} color="" />}
                        variant="text"
                        size="large"
                        disabled
                    >
                        Getting purchase details...
                    </Button>
                </Grid>
            </Box>
        )
    }

    const startFlow = () => {
        return (
            <Box>
                {header}
                <Divider />

                <Box sx={{ p: 3 }}>
                    <Checkout onPaddleCheckoutComplete={onPaddleCheckoutComplete} />
                </Box>

                <Box>
                    <Card variant="outlined" sx={{ mx: 3, mb: 3, p: 2, display: "flex" }}>
                        <TextField
                            id="creditCodes"
                            label="Manually add credit code"
                            autoComplete="off"
                            variant="standard"
                            size="small"
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Receipt size={24} />
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            sx={{ flexGrow: 1, mr: 2 }}
                        />
                        <Button
                            variant="outlined"
                            disableElevation
                            sx={{ height: 36, alignSelf: "end" }}
                            onClick={() =>
                                addCreditCode(document.getElementById("creditCodes").value)
                            }
                        >
                            Add Code
                        </Button>
                    </Card>
                </Box>
            </Box>
        )
    }

    if (creditCode) {
        return checkoutComplete()
    }
    if (transactionId) {
        return hasTransactionId()
    }
    return startFlow()
}
